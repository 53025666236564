// -------------------------
// --- You can add your variables to overwrite default values.
// -------------------------

// Uncomment one of the following line if you want to get appropriate theme.
// @import "themes/red";
@import "themes/blue";
//@import 'themes/black';
// @import "themes/green";

$light-accent-color: #e2eaff;
$round-border: #dddddd;
$nav-panel-bg: #fff;
$dark-gray: #2f2f2f;
$medium-gray: #646464;
$light-gray: #979797;
$backgroud-gray: #fafafa;
$mobile-header-menu-button-default-bg: $accent-color !default;
$border-grey: #f1f5f4;
$footer-bg: #021f31;
$font-weight-medium: 600;
$indicator-default-counter-bg: $accent-color;
$indicator-default-counter-font-color: #fff;
$card-border-radius: 7px;
$footer-text-color: #fff;
$footer-head-color: #00bcb4;
$dark-accent-color: #021f31;
$third-accent-color: #96b0b1;
