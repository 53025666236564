/*
// .footer-thirdparty
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.footer-thirdparty {
}
.footer-thirdparty__title {
    margin-bottom: 25px;
    color:$footer-text-color;
}
.footer-thirdparty__text {
    font-size: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
    color:$footer-text-color;
    line-height: 32px;
    letter-spacing: 0.03em;
    margin-bottom: 10px;

    @include direction {
        #{$padding-inline-end}: 20px;
    }

    a {
        color: $footer-text-color;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .footer-thirdparty {
        text-align: center;
    }
    .footer-thirdparty__title {
        font-size: 24px;
        margin-bottom: 16px;
    }
    .footer-thirdparty__text {
        padding: 0;
        line-height: 26px;
    }
}
