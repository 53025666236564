/*
// .site-header
*/
@import "../variables";
@import "../functions";
@import "../mixins/direction";

.site-header {
    background-color: $accent-color;
    position: relative;
    box-shadow: 0 1px rgba(#000, 0.04), inset 0 -1px rgba(#000, 0.04);
}

.site-header__middle {
    display: flex;
    align-items: center;
    height: 90px;
    position: relative;
}
.site-header__logo {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $header-logo-color;

    @include direction {
        //   #{$margin-inline-end}: 30px;
    }

    a {
        color: inherit;
    }

    svg {
        fill: currentColor;
    }
}
.site-header__search {
    flex-grow: 1;
}
.site-header__phone {
    line-height: 1;
    flex-shrink: 0;

    @include direction {
        text-align: $inline-end;
        #{$margin-inline-start}: 80px;
    }
}
.site-header__phone-title {
    font-size: 14px;
    color: $header-font-muted-color;
    margin-bottom: 6px;
}
.site-header__phone-number {
    font-size: 20px;
    font-weight: $font-weight-bold;
}
.site-header__nav-panel {
    height: $nav-panel-height;
}

.site-header__user {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 0 8px;
    padding-left: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg {
        fill: #fff;
    }
    span {
        &.indicator__value {
            background-color: #3452FF;
            color: #ffffff;
        }
    }
    .login-text {
        text-transform: uppercase;
    }
}

.header-menu {
    margin: 0 22px;
    margin-left: 10px;
    ul {
        margin-bottom: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
    }
    @media (max-width: 1199px) {
        margin: 0 18px;
    }
}
.header-menu ul li a {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    padding: 2px;
    margin: 0 22px;
    @media (max-width: 1199px) {
        margin: 0 18px;
    }
}
.header-menu ul li {
    position: relative;
    // margin-top: 8px;
    display: inline-block;
    @media (max-width: 768px) {
        display: none;
    }
}
.header-menu ul li a:hover {
    color: $third-accent-color;
    transition: 0.3s;
}
// .header-menu ul li a:hover::before {
//     content: "";
//     width: 0;
//     height: 0;
//     top: -10px;
//     border-left: 4px solid transparent;
//     border-right: 4px solid transparent;
//     border-top: 8px solid $accent-color;
//     position: absolute;
//     left: 50%;
//     bottom: 10px;
//     transform: translateX(-50%);
//     transition: 0.3s;
// }
.header .menu-right {
    display: unset;
}
.header .menu-right .search-box,
.header .menu-right .icon-nav {
    display: inline-block;
}
.header.menu-right .menu-right-top {
    text-align: right;
}
.header-logo {
    width: 160px;
    height: auto;
}
.mobile-header-logo {
    width: 135px;
    height: auto;
    @media (max-width: 480px) {
        width: 100%;
    }
}

.sticky-header {
    position: fixed;
    z-index: 30;
    right: 0;
    top: 0;
    left: 0;
    display: none;

    @media (max-width: 767px) {
        display: none !important;
    }
}

.menu-right-top {
    position: absolute;
    top: -6px;
    right: 30px;
    margin: 6px 0 2px 0;
    color: white;
}
.menu-right-top a {
    font-size: 12px;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.menu-right-top p i {
    font-size: 13px;
}
.user-avatar {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    cursor: pointer;

    img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .site-header__logo {
        justify-content: flex-start;

        @include direction {
            // #{$margin-inline-end}: 24px;
        }
    }
}

@-webkit-keyframes smoothScroll {
    0% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
@keyframes smoothScroll {
    0% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
