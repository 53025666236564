/*
// .block-features
*/
@import "../variables";

.block-empty {
}
.block-empty__body {
    text-align: center;
    padding: 60px 0;
}
.block-empty__message {
    font-size: 17px;
}
.block-empty__actions {
    margin-top: 24px;
}
.block-empty-page {
    min-height: 60vh;
    display: flex;
    justify-content: center;
}