.time-box {
    border: 1px solid $accent-color;
    padding: 2px 6px;
    font-size: 1.3rem;
    background-color: $accent-color;
    border-radius: 5px;
    color: #fff;
}
.custom-recommended {
    padding: 0 15px 50px 15px;
}
.custom-recommended .main-box {
}

.custom-recommended .title1 .title-inner1 {
    font-size: 24px;
}

.custom-recommended .sc-ifAKCX.bqyzQp {
    max-width: 100%;
}

.custom-tab-style {
    padding-bottom: 30px;
}

.custom-tab-style .tab-title li,
.custom-tab-style .tab-title2 li {
    padding-bottom: 15px;
    padding-top: 15px;
    margin-top: 20px;
    line-height: 1;
}

.custom-tab-style .tab-title {
}

.custom-tab-style .react-tabs__tab--selected {
    border-bottom: 2px solid $accent-color !important;
}

.custom-tab-style .react-tabs__tab {
    font-weight: 700;
    color: #b5b5b5 !important;
}
.custom-tab-style .react-tabs__tab--selected {
    color: black !important;
}

.featured-banner-container {
    padding-top: 20px;
}

.section-b-space {
    padding-bottom: 0;
}

.custom-recommended .section-b-space {
    padding-bottom: 30px;
}

.custom-tab-style .hNAxas,
.selected-trending .hNAxas {
    margin: 0;
}

.selected-trending .title1 .title-inner1 {
    font-size: 24px;
}

@media only screen and (max-width: 1200px) {
    .custom-recommended {
        padding: 0 15px 30px 15px;
    }
    .custom-recommended .main-box {
        padding-left: 15px;
        padding-right: 15px;
    }
    .custom-recommended .main-box .section-b-space {
        padding-bottom: 15px;
    }
    .custom-recommended .main-box .section-b-space > .container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .custom-recommended .sc-EHOje.hNAxas {
        margin-left: 10px;
        margin-right: 10px;
    }

    .custom-tab-style .tab-title {
        margin-left: 10px;
        margin-right: 10px;
    }

    .custom-tab-style .sc-gzVnrw.gmxRdC,
    .custom-tab-style .sc-gzVnrw.hmyXB {
        grid-gap: 0;
    }
}

@media only screen and (max-width: 767px) {
    .custom-recommended {
        padding: 0 15px 30px 15px;
    }
    .custom-recommended .main-box {
        padding-left: 5px;
        padding-right: 5px;
    }
    .custom-recommended .main-box .section-b-space {
        padding-bottom: 8px;
    }
    .custom-recommended .main-box .section-b-space > .container {
        padding-left: 3px;
        padding-right: 3px;
    }

    .custom-recommended .sc-EHOje.hNAxas {
        margin-left: 5px;
        margin-right: 5px;
    }

    .custom-tab-style .tab-title {
        margin-left: 5px;
        margin-right: 5px;
    }

    .custom-tab-style .sc-gzVnrw.dIlnie {
        grid-gap: 0;
    }

    .custom-tab-style .sc-bdVaJa.MMbPG,
    .custom-tab-style .sc-bdVaJa.MMbPG {
        display: none;
    }

    .selected-trending {
        padding: 40px 0;
    }

    .selected-trending .eTUOBw {
        grid-gap: 0;
    }

    .selected-trending .sc-bdVaJa.MMbPG,
    .selected-trending .sc-bdVaJa.MMbPG {
        display: none;
    }
}
