/*
// .modal
*/
@import "../variables";

.modal {
    -webkit-overflow-scrolling: touch;
}
.modal-header {
    border-bottom: 0;
    padding: 1rem 1rem 5px;
}
.modal-content {
    border: none;
    border-radius: $modal-border-radius;
    box-shadow: $modal-shadow;
}
.modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    button {
        outline: none !important;
        opacity: 1;
        background: transparent;
        border: none;
        svg {
            fill: $accent-color;
        }
    }
    &.gray {
        button svg {
            fill: $medium-gray;
        }
    }
}
