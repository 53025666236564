.block-no-result {
    padding: 65px 0;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg{
        height: 200px;
        width: auto;
        fill: $accent-color;
        color: $accent-color;
    }
}
.block-no-result-title {
    font-size: 24px;
    font-weight: $font-weight-medium;
    margin-top: 20px;
    &.small{
        font-size: 20px;
    }
}
.block-no-result-subtitle {
    margin-bottom: 24px;
}
