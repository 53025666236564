.mobile-usermenu {
  visibility: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: visibility 0s $local-transition-duration;

  @include direction {
    right: 0;
  }
}
.mobile-usermenu__backdrop {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: $mobilemenu-backdrop-bg;
  opacity: 0;
  transition: opacity $local-transition-duration;

  @include direction {
    right: 0;
  }
}

.mobile-usermenu__body {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: $mobilemenu-bg;
  box-shadow: $mobilemenu-shadow;
  transition: transform $local-transition-duration;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @include direction {
    right: 0;
    transform: translateX(calc(-100% * -1 - 30px * -1));
  }
}

.mobile-usermenu__header {
  background-color: $block-highlighted-bg;
  padding: 10px 0 30px;
}

.mobile-usermenu--open {
  visibility: visible;
  transition-delay: 0s;

  .mobile-usermenu__backdrop {
    opacity: 0.9;
  }

  .mobile-usermenu__body {
    @include direction {
      transform: translateX(0);
    }
  }
}

.back-arrow {
  fill: #9195ae;
  cursor: pointer;
  svg {
    height: 25px;
  }
}

.mobile-usermenu__user-avatar {
  width: 100%;
  align-items: center;
  text-align: center;
  
  img {
    width: 110px;
    height: 110px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 50%;
    object-fit: cover;
    @media (max-width:420px) {
      width: 90px;
      height: 90px;
    }
  }
  .name {
    font-size: 18px;
    font-weight: $font-weight-medium;
  }
  .points{
    color: $light-gray;
  }
}

.user-menu-list{
    padding: 20px 5px;
    font-size: 18px;
    text-align: center;
    li{
        list-style: none;
        padding: 8px;
        a{
            color:#515365 ;
        }
    }
    hr{
        width:90%
    }
}
