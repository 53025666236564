// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@media (min-width: 1330px) {
    .container {
        max-width: 1368px;
    }
}

@media (min-width: 1560px) {
    .container {
        max-width: 1536px;
    }
}

@media (hover: hover) {
    a {
        &:hover {
            color: $accent-color;
        }
    }
}
input[type="text"],
input[type="button"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
a[type="button"] {
    -webkit-appearance: none;
}
.text-color-theme {
    color: $accent-color;
}
.text-medium-gray {
    color: $medium-gray;
}
.c-pointer {
    cursor: pointer;
}
.background-gray {
    background-color: #f5f5f5;
}
.link-button {
    cursor: pointer;
    color: $accent-color;
    &:hover {
        text-decoration: underline;
    }
}
.hover12 figure {
    background: $accent-color;
    border: $round-border;
    margin: 0 0 0;
}
.hover12 figure img {
    opacity: 1;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.hover12 figure:hover img {
    opacity: 0.5;
}
.react-tel-input {
    .form-control {
        width: 100% !important;
        border-radius: 2px !important;
        height: inherit;
    }
}
.tnC-content {
    padding: 10px 25px;
    height: 420px;
    overflow: auto;
    ol {
        padding-inline-start: 18px;
        font-size: 18px;
    }
    @media (max-width: 768px) {
        padding: 10px 10px;
    }
}
.slider-arrow {
    position: absolute;
    z-index: 5;
    top: 18%;
    padding: 13px;
    cursor: pointer;
    fill: #4c4c4c;

    svg {
        width: 20px;
        height: 20px;
        position: relative;
    }

    &.left {
        left: 35px;
        svg {
            left: -1px;
        }
    }
    &.right {
        right: 35px;
        svg {
            right: -1px;
        }
    }

    @media (max-width: 579px) {
        &.left {
            left: 20px;
        }
        &.right {
            right: 20px;
        }
    }
}

button:focus,
.btn.focus,
.btn:focus {
    &.btn {
        outline: none;
        box-shadow: none;
    }
}

.load-more-btn {
    outline: none !important;
    color: $accent-color;
    display: inline-flex;
    background-color: #ffffff;
    padding: 6px 20px;
    border: 1px solid $card-border-color;
    align-items: center;
    transition: all $local-transition-duration ease-in-out;

    svg {
        fill: $accent-color;
        margin-right: 10px;
    }

    &:hover {
        color: #ffffff;
        display: inline-flex;
        background-color: $accent-color;
        svg {
            fill: #ffffff;
        }
    }
}
.round-label {
    background: #808080;
    color: #fff;
    width: 100px;
    border-radius: 13px;
    height: auto;
    font-size: 13px;
    padding: 3px;
    text-align: center;
    margin-left: auto;
}
.light-background-section {
    background-color: $backgroud-gray;
    padding: 80px 15px;
    @media (max-width: 576px) {
        padding-top: 20px;
    }
}
.title1 .title-inner1 {
    font-size: 23px;
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative;
    text-transform: none;
    color: #003128;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
    @media (max-width: 580px) {
        font-size: 21px;
    }
}
.title1 .title-inner1:after {
    position: absolute;
    height: 5px;
    width: 70px;
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centered h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
}
/** for mobile **/
@media (max-width: 576px) {
    .categories-img {
        padding: 10px;
    }
    .categories-btn {
        padding-top: 5px;
        padding-bottom: 5px;
        min-width: 120px;
        height: 35px;
        font-size: 11px;
    }
    .material-container {
        margin-bottom: 20px;
    }
    .material-row {
        margin-bottom: 0px;
    }
}

/** for tablet **/
@media (min-width: 641px) and (max-width: 1024px) {
    .material-container {
        margin-bottom: 20px;
    }
    .material-row {
        margin-bottom: 0px;
    }
}

.home-category-box {
    position: relative;
    height: 100%;
    margin-bottom: 15px;
    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .title-box {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        bottom: 20px;
    }
}

.slider-arrow-circle {
    position: absolute;
    z-index: 5;
    top: 30px;
    background-color: white;
    border-radius: 50%;
    opacity: 0.6;
    padding: 8px;
    cursor: pointer;
    fill: #393b3b;

    svg {
        width: 13px;
        height: 13px;
        position: relative;
    }

    &.left {
        left: -15px;
        box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.2);
        svg {
            left: -1px;
        }
    }
    &.right {
        right: -15px;
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2);
        svg {
            right: -1px;
        }
    }
    @media (max-width: 768px) {
        display: none;
    }
}
.vproduct-section-2 .container {
    @media (max-width: 768px) {
        max-width: 100%;
    }
}
.vproduct-section-2 {
    // background-color: #fff;
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 10px 50px 10px;
    @media (max-width: 768px) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    @media (max-width: 580px) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.vbrands-section .container {
    padding: 30px;
    @media (max-width: 1200px) {
        padding: 0;
    }
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 30px;
    }
    @media (max-width: 580px) {
        max-width: 100%;
        padding: 20px;
    }
    @media (max-width: 480px) {
        max-width: 100%;
        padding: 10px;
    }
}
.vproduct-section-3 {
    display: flex;
    justify-content: center;
    padding: 50px 130px;
    @media (max-width: 1560px) {
        padding: 50px 0;
    }
    @media (max-width: 1200px) {
        padding: 30px 0;
    }
    @media (max-width: 768px) {
        padding: 20px;
    }
    @media (max-width: 580px) {
        padding: 0px;
    }
}
.vproduct-section-3 .category-box {
    background: #fafafa;
    width: calc(16.66666% - 10px);
    @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)) {
        width: calc(25% - 10px);
    }
    @media (max-width: breakpoint(md-end)) and (min-width: breakpoint(md-start)) {
        width: calc(33.33333% - 10px);
    }
    @media (max-width: breakpoint(sm-end)) {
        width: calc(50% - 10px);
    }
}
.vproduct-section-3 .category-box,
.vbrands-section .brand-box {
    border: 1px solid #e8e8e8;
    margin: 0 5px;
    margin-bottom: 10px;
    transition: 0.3s;
    border-radius: 6px;
}
.vproduct-section-3 .category-box:hover,
.vbrands-section .brand-box:hover {
    box-shadow: 0 0 12px 4px #bfbfbf;
    @media (max-width: 1200px) {
        box-shadow: 0 0 8px 2px #bfbfbf;
    }
}
.category-box .img-wrapper {
    position: relative;
    height: 200px;
    width: 100%;
    // border-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    @media (max-width: 480px) {
        height: 170px;
    }
}
.brands-box {
    padding: 5px 10px;
    .img-wrapper {
        height: 85px;
        img {
            object-fit: contain;
        }
    }
}
.img-wrapper {
    position: relative;
    height: 240px;
    width: 100%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @media (max-width: 1200px) {
        height: 220px;
    }
    @media (max-width: 580px) {
        height: 180px;
    }
}
.vbrands-section .item-detail {
    text-align: center;
    padding: 5px;
    a {
        color: #2e2e2e;
    }
}
.vproduct-section-3 .item-detail {
    text-align: center;
    text-transform: uppercase;
    padding: 10px;
    font-weight: 600;
    font-size: 15px;
    color: #7a7a7a;
    transition: all 0.5s ease;

    a {
        color: #2e2e2e;
        overflow: hidden;
        flex-grow: 1;
        display: -webkit-inline-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
.banner-container {
    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0px;
    }
}
.no-result-container {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.infinite-scroll-component {
    overflow: hidden !important;
}
.wyswyg_title {
    text-align: center;
    padding-top: 30px;
}
.wyswyg-wrapper {
    padding: 60px 20px;
}
.wyswyg-section {
    padding: 40px 10px;
}
.react-page-cell {
    display: initial;
}
.slick-track {
    margin-left: 0;
}
.gly_li{
    width: 50%;
    float: left;
    color: $medium-gray;
}
.gly_li:hover{
    color: $accent-color;
}
@media only screen and (max-width: 1200px){
    .gly_li{
        width: 100%;
        float: none;
    }
}

.dashboard_list{
    margin:25px 0px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.dashboard_box{
    flex-direction: column;
    background: #ffffff;
    text-align: center;
    align-items: center;
    padding: 30px 15px;
    border-radius: 7px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 12%);
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
}
.dashboard_content{
     width: 100%;
}
.dashboard_box_divider {
    flex-shrink: 0;
}
.dashboard_body{
    min-height: 22px;
    padding: 10px;
    color: #6e6b7b;
}
@media (min-width: breakpoint(lg-start)) {
    .dashboard_box_divider{
        width: 14px;
    }
}
@media (min-width: breakpoint(md-start)){
    .dashboard_box_divider{
        width:14px;
    }
}
@media (min-width: 480px)  and (max-width: breakpoint(md-start)) {
    .dashboard_box_divider{
        width: 100%;
        height: 10px;
    }
}
@media (max-width: 479px) {
    .dashboard_box_divider{
        width: 100%;
        height: 4px;
    }
}
.section-title {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 567px) {
        font-size: 23px;
    }
}

.password-eye {
    cursor: pointer;
    float: right;
    margin-right: 6px;
    margin-top: -26px;
    position: relative;
    z-index: 2;
}

.wallet-type__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}
.wallet-type__item {
    float: left;
}
.wallet-type__item-header {
    display: flex;
    align-items: center;
    padding: 8px 14px;
    font-size: 15px;
    font-weight: $font-weight-normal;
    letter-spacing: 0.01em;
    cursor: pointer;
    margin-bottom: 0;
}
.wallet-type__item-radio {
    @include direction {
        #{$margin-inline-end}: 10px;
    }
}
.wallet-type__item-container {
    overflow: hidden;
    height: 0;
    transition: height 0.2s;
}
.wallet-type__item-description {
    font-size: 15px;
    line-height: 20px;
    padding: 2px 16px 13px 16px;
}
.wallet-type__item--active {
    .wallet-type__item-container {
        height: auto;
    }
}

// rank
.img-fluid{
    height: 15rem;
    object-fit: cover;
}
.listing-icon{
    margin-right: 10px;
}
.card-text{
    margin-bottom: 5px;
}
.rank-300 {
    width: 30px;
    height: 30px;
    background-image: url("../svg/rank/300-small.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 4px 10px;
}
.rank-400 {
    width: 30px;
    height: 30px;
    background-image: url("../svg/rank/400-small.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 4px 10px;
}
.rank-500 {
    width: 30px;
    height: 30px;
    background-image: url("../svg/rank/500-small.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 4px 10px;
}
.rank-600 {
    width: 30px;
    height: 30px;
    background-image: url("../svg/rank/600-small.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 4px 10px;
}
.rank-700 {
    width: 30px;
    height: 30px;
    background-image: url("../svg/rank/700-small.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 4px 10px;
}
.big-rank-img{
    width: 3rem;
    padding: 5px;
}
.rank-img-list img{
    object-fit: cover;
    // height: 4rem;
}
.rank-img-list div{
    float: left;
    margin: 0.25rem;
    padding: 0.25rem;
}
.rst__rowContents {
    box-shadow: 0 4px 5px 0 rgba(34, 41, 47, 0.10) !important;
    border-radius: .428rem !important;
}
.rst__rowSubtitle{
    margin-left: 36px;
    color: #1d67ac;
}
@media (max-width: 480px){
    .mobile-header-logo {
        width: 65%;
        margin-bottom: 10px;
    }
}

.profile-wallet-section {
    // display: flex;
    justify-content: space-between;
    font-weight: bolder;
    font-size: 16px;
    
    span{
        font-weight: normal;
    }
    .wallet-topup {
        color: #2e2e2e;
        display: flex;
        font-weight: 600;
        width: fit-content;
        &:hover {
            color: #007bff;
        }
        .fa-whatsapp {
            align-self: center;
            color: #25d366;
            font-size: 20px;
        }
    }

    @media (max-width: 420px){
        display: block;
        font-size: 17px;
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        font-size: 15px;
    }
}

.url-span {
    // color: $accent-color;
    cursor: pointer;
    // font-weight: bold;
}


.FullScreenComponent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 9999;
}

.FullScreenCloseButton {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    border: none;
    color: black;
    font-size: 24px;
    cursor: pointer;
    z-index: 99999;
}

.FullScreenContent {
    position: relative;
    background-color: transparent;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.badge-custom {
    display: inline-block;
    font-size: 85%;
    font-weight: 600;
    line-height: 1;
    white-space: nowrap;
    vertical-align: middle;
    padding: 0.3rem 0.5rem;
    border-radius: 0.358rem;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
}

.badge-custom-danger {
    background-color: rgba(235, 32, 65, 0.12);
}

.badge-custom-warning {
    background-color: rgba(255, 193, 7, 0.12);
}

.badge-custom-success {
    background-color: rgba(76, 175, 80, 0.12);
}

.badge-custom-danger.badge {
    color: #eb2041 !important;
}

.badge-custom-warning.badge {
    color: #ffc107 !important;
}

.badge-custom-success.badge {
    color: #4caf50 !important;
}

.order-link:hover {
    color: #212529;
    text-decoration: underline;
}

.announcement-title {
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
    display: -webkit-box; /* Required for webkit-based browsers like Safari */
    -webkit-line-clamp: 2; /* Limit the content to 2 lines */
    -webkit-box-orient: vertical;
}

.announcement-title-box {
    background-color: #FFFFFF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 100%; /* Added to limit the width of the title */
    text-align: center;
    height: 60px;
    position: relative; /* Add relative positioning */
    padding: 5px; /* Add padding */
    transition: box-shadow 0.3s;
    @media (max-width: 767px) {
        font-size: 14px;
        height: 50px;
        padding: 5px; 
    }
    @media (max-width: 480px) {
        font-size: 16px;
        height: 60px;
        padding: 5px; 
    }
}
.announcement-item-box {
    border-radius: 10px;
}
.announcement-item-box:hover {
    box-shadow: 0 0 2px 2px #bfbfbf;
}

.block-announcement__slide {
    position: relative;
    justify-content: center;
    color: inherit;
    vertical-align: middle;
    width: calc(16.66666% - 10px);
    // border: 1px solid #e8e8e8;
    transition: 0.3s;
    border-radius: 6px;
    width: 300px;
    height: 300px;
}

.block-announcement__slide img {
    height: 330px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @media (max-width: 1600px) {
        height: 305px;
    }
    @media (max-width: 1400px) {
        height: 290px;
    }
    @media (max-width: 1340px) {
        height: 350px;
    }
    @media (max-width: 1200px) {
        height: 290px;
    }

    @media (max-width: 990px) {
        height: 310px;
    }
    @media (max-width: 760px) {
        height: 230px;
    }
    @media (max-width: 480px) {
        height: 350px;
    }
    @media (max-width: 440px) {
        height: 320px;
    }
    @media (max-width: 400px) {
        height: 280px;
    }
    @media (max-width: 360px) {
        height: 230px;
    }
    @media (max-width: 310px) {
        height: 200px;
    }
    @media (max-width: 260px) {
        height: 160px;
    }
}
.block-announcement__body {
    display: flex;
    justify-content: center;
}

.announcement__carousel {
    
    margin-top: 15px;
    margin-bottom: 30px;
    .slick-list {
        margin: 0 -5px;
    }
    .slick-dots {
        bottom: -40px !important;
    }
    .slick-dots li button:before{
        font-size: 11px;
        color: #ADADAD;
    }

    .slick-dots li.slick-active button:before{
        color: $accent-color;
    }

    .slick-dots li:hover button:before {
        color: #909090;
    }
    .slick-slide {
        padding: 5px 15px;
    }
    @media (max-width: 1600px) {
        .slick-slide {
            padding: 5px 15px; /* Adjusted padding for smaller screens */
        }
    }
    @media (max-width: 1330px) {
        .slick-slide {
            padding: 5px 10px; /* Adjusted padding for smaller screens */
        }
    }
    @media (max-width: 1200px) {
        .slick-slide {
            padding: 5px 10px; /* Adjusted padding for smaller screens */
        }
    }
    @media (max-width: 770px) {
        .slick-slide {
            padding: 5px 8px; /* Adjusted padding for smaller screens */
        }
    }
    @media (max-width: 480px) {
        .slick-slide {
            padding: 5px 40px; /* Adjusted padding for smaller screens */
        }
    }

    .slick-track {
        @include direction {
            #{$margin-inline-start}: 0;
        }
    }
}
.announcement__carousel-item {
    vertical-align: middle;
    
    width: 100%;
    cursor: pointer;
    // display: block;
    box-shadow: $product-gallery-item-default-shadow;
    padding: 2px;
    border-radius: $product-gallery-item-border-radius;
    border: none;
    background: transparent;

    &:focus {
        outline: none;
    }
}

.section-AnnouncementTitle {
    font-size: 26px;
    text-align: center;
    @media (max-width: 567px) {
        font-size: 23px;
    }
}

.announcement__carousel-button {
width: 40px;
height: 40px;
}

.deposit-button {
    cursor: pointer;
    border: 2px solid $accent-color;
    margin-right: 20px;
    border-radius: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    padding-right: 7px;
    width: fit-content;
}



